/***************************************************
  Imports
***************************************************/

import React from "react"

import PageTypeI from "../../templates/PageTypeI"
import List from "../../components/List"

import TitleImg from "../../images/simcrest-apps-cash-basis.png"

import IconManual from "../../images/icon-manual.svg"
import IconLicense from "../../images/icon-license.svg"
import IconPrivacy from "../../images/icon-privacy.svg"

import BlueArrow from "../../images/blue-arrow.svg"

/***************************************************
  Page Data 
***************************************************/

const summaryInfo = () => {
  return (
    <div className="simcrest-apps summary-info">
      <p>
        SimCrest Cash Basis allows you to calculate your General Ledger using
        both Accrual (earned) and Cash Basis Accounting principles (cash flow).
      </p>
      <span>Features and Benefits</span>
      <List
        flex
        className="concise dark"
        items={[
          {
            text: `Cash Basis App adds Cash Basis accounting to your General Ledger in Dynamics 365 Business Central.`,
          },
          {
            text: `Report Income Statement and Balance Sheet in Cash basis or Accrual Basis.`,
          },
          {
            text: `Automatically handle overpayments, and closing of income statement for Cash Basis.`,
          },
          {
            text: `Cash Basis does not only eliminate accounts receivables and payables, but also distributes revenue and expenses to all the relevant accounts in your income statement based on posted sales and purchase documents.`,
          },
          {
            text: `General Journal entries can be excluded from cash basis, so they only affect the accrual ledger.`,
          },
          {
            text: `Cash Basis adjustments that only affect cash basis can be done through a cash adjustment journal.`,
          },
          {
            text: `Cash Basis accounting year can be separate from the accrual accounting year.`,
          },
        ]}
      />
      <div>
        <img src={TitleImg} />
        <div>
          <p>
            SimCrest Cash Basis is easy to use and saves you a lot of time!
            <br />
            <br />
            Cash Basis can be acquired as an App in the Extension Market Place
            inside Dynamics 365 Business Central or as an On Premise App. Just
            click Free trial to install and try out the solution.
          </p>
          <div className="blue-arrow-link">
            <img className="blue-arrow" src={BlueArrow} alt="Arrow" />
            <a
              href="https://appsource.microsoft.com/en-us/product/dynamics-365-business-central/PUBID.sim_crest%7CAID.cash_basis%7CPAPPID.52c5c025-b198-4deb-870a-95ca543bdd80"
              target="_blank"
            >
              See the App on Dynamics 365 Business Central
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

/***************************************************
  Component
***************************************************/

export default () => {
  return (
    <PageTypeI
      title="Cash Basis"
      description="SimCrest Cash Basis allows you to calculate your General Ledger using both Accrual (earned) and Cash Basis Accounting principles (cash flow)."
      linkSubPageGroupID="SimCrestAppsGroup"
      subTitle="Cash Basis for Microsoft Dynamics 365 Business Central"
      summaryInfo={summaryInfo}
      videos={[
        {
          title: "Demo of Cash Basis",
          embedCode: (
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/ZLe7ttMrbMA"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          ),
        },
      ]}
      documents={[
        {
          title: "Manual",
          link: "/docs/manual-cash-basis.pdf",
          icon: IconManual,
        },
        {
          title: "License",
          link: "/docs/license-cash-basis.pdf",
          icon: IconLicense,
        },
        {
          title: "Privacy",
          link: "/docs/privacy-cash-basis.pdf",
          icon: IconPrivacy,
        },
      ]}
    />
  )
}
